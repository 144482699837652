'use client'

import { AlertDialog, Button, Flex } from "@radix-ui/themes";
import { useSession } from "next-auth/react"
import { useTheme } from "next-themes";
import Head from "next/head";
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react";

export default function Template({ children }: { children: React.ReactNode }) {
    const pathname = usePathname();
    const { data: session, status } = useSession(
        { required: pathname !== '/auth/signin' }
    );
    const [alertLoaded, setAlertLoaded] = useState<boolean>(false);
    const [alertData, setAlertData] = useState<any>(null);
    const theme = useTheme();

    if (status === 'loading') return null;
    if (session === undefined) return null;
    
    return (
        <>
            <Head>
                <meta name='color-scheme' content={theme.resolvedTheme} />
            </Head>
            <AlertDialog.Root>
                <AlertDialog.Content style={{ maxWidth: 450 }}>
                    <AlertDialog.Title>Revoke access</AlertDialog.Title>
                    <AlertDialog.Description size="2">
                    Are you sure? This application will no longer be accessible and any
                    existing sessions will be expired.
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                    <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                        Cancel
                        </Button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                        <Button variant="solid" color="red">
                        Revoke access
                        </Button>
                    </AlertDialog.Action>
                    </Flex>
                </AlertDialog.Content>
            </AlertDialog.Root>
            {children}
        </>
    )
}